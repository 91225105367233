import { Filter } from "appDtos";

function formatPhoneNumber(phoneNumber: string) {
  const cleaned = phoneNumber.replace(/\D/g, '');
  if (cleaned.length === 10) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  } else {
    return cleaned;
  }
}

export function isValidPhoneNumber(phone: string): boolean {
  const phoneRegex = /^\+?[1-9]\d{1,14}$/;
  return phoneRegex.test(phone.replace(/\D/g, ""));
}

function formatDate(inputDateString: string | null) {
  if (!inputDateString) {
    return null;
  }
  const inputDate = new Date(inputDateString as string);
  //const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return inputDate.toLocaleDateString();
}

export const filterSorter = (a: Filter, b: Filter) => {
  const aV = a.value.toUpperCase();
  const bV = b.value.toUpperCase();

  if (aV < bV) {
    return -1;
  }
  if (aV > bV) {
    return 1;
  }

  return 0;
}

export const mapColor = (status: string) => {
const s = status.toLowerCase();
if (['disposed', 'rejected'].includes(s)) return 'text-danger';
if (s.includes('pending')) return 'text-warning';
return 'text-success';
}

export { formatPhoneNumber, formatDate }