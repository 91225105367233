import { useEffect, useState } from 'react'
import { PageHeader } from '../../../shared/components';
import { UpdateUserDetailRequest, UserDetailDto } from 'appDtos';
import { Container, Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import { AdministrationPaths } from '../../../shared/api/constants';
import { AppPaths } from '../../../shared/appConstants';
import { AlertComponent } from '../../../shared/components';
import { useApi } from '../../../shared/hooks/useApi';
import { useApiHandleError } from '../../../shared/hooks/useApiHandleError';
import { isValidEmail } from '../../../shared/utils/validators';
import { Loading } from '../../../shared/components';
import { BackButton } from '../../../shared/components';
import { formatPhoneNumber, isValidPhoneNumber } from '../../../shared/utils/utils';
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../../../shared/components';
import { Required } from '../../../shared/components';

export function EditUser() {
  const { id } = useParams();
  const { setError, errorMessage } = useApiHandleError();
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormError] = useState<any>({});
  const goTo = useNavigate();
  const API = useApi();

  const [user, setUser] = useState<UpdateUserDetailRequest>({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    isActive: false
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get<UserDetailDto>(AdministrationPaths.GetUserDetail(id as string));
        if (response.data) {
          setUser({
            id: response.data.id,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            email: response.data.email,
            phoneNumber: response.data.phoneNumber,
            isActive: response.data.isActive
          });
        }
      } catch (error) {
        setError(error)
      }
      setLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (event: any) => {

    const { name, value } = event.target;
    if (formErrors[name]) setFormError({ ...formErrors, [name]: '', });
    if (errorMessage) setError('');

    setUser({ ...user, [name]: name === 'isActive' ? !user.isActive : value });
  };

  const handleSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();

    const formErrors = { firstName: '', lastName: '', email: '', confirmedEmail: '', phoneNumber: '' };

    if (!user.firstName) formErrors.firstName = 'First name is required.';
    if (!user.lastName) formErrors.lastName = 'Last name is required.';
    if (!isValidEmail(user.email)) formErrors.email = 'Email is not valid.';
    if (user.phoneNumber && !isValidPhoneNumber(user.phoneNumber)) formErrors.phoneNumber = 'Phone number is not valid. Only numbers are allowed.';

    if (Object.values(formErrors).some(v => v)){
      setLoading(false);
      return setFormError(formErrors);
    }

    (async () => {
      try {
        const response = await API.put<UpdateUserDetailRequest>(AdministrationPaths.UpdateUserDetail(), user);
        if (response?.data) {
          goTo(AppPaths.getUserDetail(response.data))
        }
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    })();
  };

  return (
    <Container>
      <PageHeader pageTitle='Edit User' />
      <AlertComponent message={errorMessage} />
      {loading ? (
        <Loading />
      ) : user.id ? <Form noValidate onSubmit={handleSubmit}>
        {/* First Name */}
        <Form.Group controlId="firstName" className="mt-3">
          <Form.Label className="fw-bold">First Name<Required /></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            autoComplete="off"
            name="firstName"
            value={user.firstName}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.firstName)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.firstName}</Form.Control.Feedback>
        </Form.Group>

        {/* Last Name */}
        <Form.Group controlId="lastName" className="mt-3">
          <Form.Label className="fw-bold">Last Name<Required /></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            autoComplete="off"
            name="lastName"
            value={user.lastName}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.lastName)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.lastName}</Form.Control.Feedback>
        </Form.Group>

        {/* Email */}
        <Form.Group controlId="email" className="mt-3">
          <Form.Label className="fw-bold">Email<Required /></Form.Label>
          <Form.Control
            size="lg"
            type="email"
            name="email"
            autoComplete="off"
            value={user.email}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.email)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
        </Form.Group>

        {/* Phone */}
        <Form.Group controlId="phone" className="mt-3">
          <Form.Label className="fw-bold">Phone (optional)</Form.Label>
          <Form.Control
            size="lg"
            type="tel"
            name="phoneNumber"
            autoComplete="off"
            value={formatPhoneNumber(user.phoneNumber || '')}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.phoneNumber)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.phoneNumber}</Form.Control.Feedback>
        </Form.Group>
        {/* Is Active */}
        <Form.Group controlId="isActive" className="mt-3">
          <Form.Label className="fw-bold">Is User Active?</Form.Label>
          <Form.Check
            type="checkbox"
            name="isActive"
            checked={user.isActive}
            onChange={handleInputChange}
          />
        </Form.Group>
        <BackButton />
        <Button variant="success" type="submit" size='lg' className="mx-2 mt-5 fw-bold">
          {<Icon icon={faArrowCircleDown} />} Submit
        </Button>
      </Form> : (
        <AlertComponent message={errorMessage} />
      )}
    </Container>
  );
}
