import { Badge } from "react-bootstrap";

export const Avatar = ({ firstName = "", lastName = "" }) => {
    const getInitials = () => {
        const firstInitial = firstName.charAt(0).toUpperCase();
        const lastInitial = lastName.charAt(0).toUpperCase();
        return `${firstInitial}${lastInitial}`;
    };

    return (
        <Badge
            className="d-flex justify-content-center align-items-center text-white bg-success"
            style={{
                width: "180px",
                height: "180px",
                borderRadius: "50%",
                fontSize: "80px",
                fontWeight: "bold",
            }}
        >
            {getInitials()}
        </Badge>
    );
};
